<template>
  <v-dialog persistent v-model="dialogDetailDispute" :width="screenWidth + '%'">
    <v-card v-if="isResponsive" class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <div class="display-flex align-items-center justify-content-center">
        <p class="text-title mon-bold">
          {{ texts.disputesdetail.textTitle }}
        </p>
      </div>
      <div class="display-flex align-items-center justify-content-center">
        <p class="text-requested mon-regular">
          {{ texts.disputesdetail.requested }}:
        </p>
        <p class="text-requested-item mon-regular ml-2">
          {{ creationDate }}
        </p>
      </div>
      <div class="display-flex align-items-center justify-content-center">
        <p class="text-status mon-semibold">{{ statusName }}</p>
      </div>
      <div class="content-shadow mt-5">
        <v-row>
          <v-col cols="12" sm="6">
            <div class="display-flex align-items-center justify-content-center">
              <div>
                <p class="text-order text-center mon-regular">
                  {{ texts.disputesdetail.textOrder }}:
                </p>
                <p class="text-order-item text-center mon-regular ml-1">
                  {{ sFolioId }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="display-flex align-items-center justify-content-center">
              <div>
                <p class="text-total-order text-center mon-regular">
                  {{ texts.disputesdetail.textTotalOrder }}:
                </p>
                <p class="text-total-order-item text-center mon-semibold ml-1">
                  ${{ formatMoney(totalOrder) }}MXN
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="content-resize-image mt-5">
        <img v-if="imageSelected !== ''" :src="imageSelected" width="100%" height="100%">
        <img v-else src="@/assets/images/placeholder-image.jpeg" width="100%" height="100%">
      </div>
      <div class="display-flex align-items-center pl-2" style="overflow-x: auto;">
        <div v-for="(item, index) in aImages" :key="index" @click="selectImage(item)"
          class="content-image-responsive cursor-pointer">
          <img v-if="item.oImages.md !== ''" :src="item.oImages.md" alt="" />
          <img v-else src="@/assets/images/placeholder-image.jpeg" width="100%" height="100%">
        </div>
      </div>
      <v-container fluid>
        <v-row>
          <v-col cols="12" :lg="aImages.length > 0 ? '6' : '12'" :md="aImages.length > 0 ? '6' : '12'" sm="12">
            <v-row>
              <v-col>
                <div>
                  <p class="text-title-content-shadow mon-bold">
                    {{ texts.disputesdetail.detailDispute.textTitle }}
                  </p>
                  <div class="content-shadow pa-0">
                    <div class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                      <p class="text-id mon-medium">
                        {{ texts.disputesdetail.detailDispute.textId }}
                      </p>
                      <v-spacer />
                      <p class="text-id-item mon-regular">{{ disputeId }}</p>
                    </div>
                    <div v-if="disputeCreationDate != null" class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                      <p class="text-id mon-medium">
                        {{ texts.disputesdetail.detailDispute.textDate }}
                      </p>
                      <v-spacer />
                      <p class="text-id-item mon-regular">
                        {{ disputeCreationDate }}
                      </p>
                    </div>
                    <div class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                      <p class="text-id mon-medium">
                        {{ texts.disputesdetail.detailDispute.textCategory }}
                      </p>
                      <v-spacer />
                      <p class="text-id-item mon-regular">
                        {{ categoryName }}
                      </p>
                    </div>
                    <div class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                      <p class="text-id mon-medium">
                        {{
                            texts.disputesdetail.detailDispute.textSubcategory
                        }}
                      </p>
                      <v-spacer />
                      <p class="text-id-item mon-regular">
                        {{ subcategoryName }}
                      </p>
                    </div>
                    <div class="display-flex align-item-center pa-4">
                      <p class="text-id mon-medium">
                        {{ texts.disputesdetail.detailDispute.textManager }}
                      </p>
                      <v-spacer />
                      <p class="text-id-item mon-regular">
                        {{ managerName }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <p class="text-title-content-shadow mon-bold">
                    {{ texts.disputesdetail.descriptionDispute.textTitle }}
                  </p>
                  <div class="content-shadow">
                    <p class="text-description mon-regular">
                      {{ disputeDescription }}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <p class="text-title-content-shadow mon-bold">
                    {{ texts.disputesdetail.resolutionDispute.textTitle }}
                  </p>
                  <div class="content-shadow">
                    <p :class="disputeCreationDate != null ? 'text-description' : 'text-description-empty'"
                      class=" mon-regular">
                      {{ (disputeCreationDate != null) ? disputeResolution
                          : texts.disputesdetail.resolutionDispute.sNotResolutionMessage
                      }}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-else class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="5">
              <div class="
                  display-flex
                  align-items-center
                  
                  add-centered-text
                ">
                <p class="text-title mon-bold">
                  {{ texts.disputesdetail.textTitle }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center ">
                <p class="text-requested mon-regular">
                  {{ texts.disputesdetail.requested }}:
                </p>
                <p class="text-requested-item mon-regular ml-1">
                  {{ creationDate }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="2">
              <div class="display-flex align-items-center ">
                <p class="text-status mon-semibold">{{ statusName }}</p>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="content-shadow">
                <v-row>
                  <v-col cols="12" sm="6">
                    <div class="display-flex align-items-center ">
                      <p class="text-order mon-regular">
                        {{ texts.disputesdetail.textOrder }}:
                      </p>
                      <p class="text-order-item mon-regular ml-1">
                        {{ sFolioId }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div class="
                        display-flex
                        align-items-center
                        heigh-100
                        justify-content-flex-end
                        remove-flex-end
                      ">
                      <p class="text-total-order mon-regular">
                        {{ texts.disputesdetail.textTotalOrder }}:
                      </p>
                      <p class="text-total-order-item mon-semibold ml-1">
                        ${{ formatMoney(totalOrder) }}MXN
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="aImages.length > 0" cols="12" lg="6" md="6" sm="12">
              <div>
                <div class="display-flex resize-content">
                  <div class="content-fixed-image">
                    <div v-for="(item, index) in aImages" :key="index">
                      <div @click="selectImage(item)" class="content-image cursor-pointer">
                        <img v-if="item.oImages.md !== ''" :src="item.oImages.md" alt="" />
                        <img v-else src="@/assets/images/placeholder-image.jpeg" width="100%" height="100%">
                      </div>
                    </div>
                  </div>
                  <div class="content-resize-image">
                    <zoom-image-component :imageSelected="imageSelected" />
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <p class="text-mouse-zoom mon-medium text-center">
                  {{ texts.disputesdetail.textMouseZoom }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" :lg="aImages.length > 0 ? '6' : '12'" :md="aImages.length > 0 ? '6' : '12'" sm="12">
              <v-row>
                <v-col>
                  <div>
                    <p class="text-title-content-shadow mon-bold">
                      {{ texts.disputesdetail.detailDispute.textTitle }}
                    </p>
                    <div class="content-shadow pa-0">
                      <div class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                        <p class="text-id mon-medium">
                          {{ texts.disputesdetail.detailDispute.textId }}
                        </p>
                        <v-spacer />
                        <p class="text-id-item mon-regular">{{ disputeId }}</p>
                      </div>
                      <div v-if="disputeCreationDate != null" class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                        <p class="text-id mon-medium">
                          {{ texts.disputesdetail.detailDispute.textDate }}
                        </p>
                        <v-spacer />
                        <p class="text-id-item mon-regular">
                          {{ disputeCreationDate }}
                        </p>
                      </div>
                      <div class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                        <p class="text-id mon-medium">
                          {{ texts.disputesdetail.detailDispute.textCategory }}
                        </p>
                        <v-spacer />
                        <p class="text-id-item mon-regular">
                          {{ categoryName }}
                        </p>
                      </div>
                      <div class="
                          display-flex
                          align-item-center
                          border-bottom
                          pa-4
                        ">
                        <p class="text-id mon-medium">
                          {{
                              texts.disputesdetail.detailDispute.textSubcategory
                          }}
                        </p>
                        <v-spacer />
                        <p class="text-id-item mon-regular">
                          {{ subcategoryName }}
                        </p>
                      </div>
                      <div class="display-flex align-item-center pa-4">
                        <p class="text-id mon-medium">
                          {{ texts.disputesdetail.detailDispute.textManager }}
                        </p>
                        <v-spacer />
                        <p class="text-id-item mon-regular">
                          {{ managerName }}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div>
                    <p class="text-title-content-shadow mon-bold">
                      {{ texts.disputesdetail.descriptionDispute.textTitle }}
                    </p>
                    <div class="content-shadow">
                      <p class="text-description mon-regular">
                        {{ disputeDescription }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div>
                    <p class="text-title-content-shadow mon-bold">
                      {{ texts.disputesdetail.resolutionDispute.textTitle }}
                    </p>
                    <div class="content-shadow">
                      <p :class="disputeCreationDate != null ? 'text-description' : 'text-description-empty'"
                        class=" mon-regular">
                        {{ (disputeCreationDate != null) ? disputeResolution
                            : texts.disputesdetail.resolutionDispute.sNotResolutionMessage
                        }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "DisputesDialogDetailLayout",
  data() {
    return {
      texts: "",
      screenWidth: 0,
      imageSelected: "",
      statusName: "",
      sFolioId: "",
      disputeId: "",
      creationDate: "",
      categoryName: "",
      subcategoryName: "",
      managerName: "",
      totalOrder: 0,
      disputeDescription: "",
      aImages: [],
      disputeCreationDate: null,
      disputeResolution: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.texts = FILE.disputesdetail[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getDisputeDetail: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/claim-tickets/${this.arr.sClaimTicketId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          let arr = response.data.results;

          this.totalOrder = arr.dTotalPrice;
          this.statusName = arr.oStatus.sStatusName;
          this.sFolioId = arr.oPurchaseOrder.sFolioId;
          this.disputeId = arr.sClaimTicketId.split("-")[4];
          this.creationDate = arr.tCreatedAt;
          this.categoryName = arr.sCategoryName;
          this.subcategoryName = arr.sSubcategoryName;
          this.managerName = arr.sAdminOwnerName;
          this.disputeDescription = arr.sDescription;
          this.aImages = arr.aClaimTicketImages;
          if (arr.aClaimTicketImages.length > 0) {
            this.imageSelected = arr.aClaimTicketImages[0].oImages.lg;
          }
          this.disputeCreationDate = arr.tFinishDate;
          this.disputeResolution = arr.oStatus.sResolutionMessage;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    formatDate: function (date) {
      var new_date = MOMENT(date, "YYYY/MM/DD");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    selectImage: function (e) {
      this.imageSelected = e.oImages.lg;
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 90;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogDetailDispute", {
        active: false,
        arr: [],
      });

      this.imageSelected = "";
      this.statusName = "";
      this.sFolioId = "";
      this.disputeId = "";
      this.creationDate = "";
      this.categoryName = "";
      this.subcategoryName = "";
      this.managerName = "";
      this.totalOrder = 0;
      this.disputeDescription = "";
      this.aImages = [];
      this.disputeCreationDate = null;
      this.disputeResolution = "";
    },
  },
  computed: {
    // return create contact dialog state
    dialogDetailDispute: function () {
      return this.$store.state.dialogDetailDispute;
    },
    arr: function () {
      return this.$store.state.dialogDetailDisputeArr;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    dialogDetailDispute: function () {
      if (this.dialogDetailDispute) {
        this.texts = FILE.disputesdetail[this.selectLanguage];
        this.getDisputeDetail();
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.disputesdetail[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.text-title-content-shadow {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 5px;
}

.text-description {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-description-empty {
  text-align: left;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.text-id {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-id-item {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.container-dialog {
  width: 100%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.text-title {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-requested {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-requested-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-status {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.content-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}

.text-order {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-order-item {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-total-order {
  text-align: right;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-total-order-item {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.content-fixed-image {
  width: 125px;
  max-height: 340px;
  overflow: auto;
  padding: 10px;
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-image-responsive {
  width: 60px;
  height: 60px;
  opacity: 1;
  margin-right: 10px;
}

.content-image-responsive img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image-responsive img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-resize-image {
  width: calc(100% - 125px);
  /* height: 350px; */
  padding: 10px;
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

@media (max-width: 600px) {
  .resize-content {
    display: block;
  }

  .content-fixed-image {
    display: flex;
    width: 100%;
  }

  .content-fixed-image .content-image {
    margin: 5px;
    padding: 4px;
  }

  .content-resize-image {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .remove-flex-end {
    justify-content: flex-start;
  }

  .add-centered-text {
    justify-content: center;
  }
}

@media (max-width: 960px) {

  .text-order,
  .text-order-item {
    font-size: 14px;
  }

  .text-total-order {
    font-size: 14px;
  }

  .text-total-order-item {
    font-size: 16px;
  }
}
</style>